import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nl: {
        translation: {
          "Completely Disagree": "klopt niet",
          "Completely Agree": "klopt",
          "Score": "Uitkomst",
          "Question": "Vraag",
          "Answer": "Antwoord",
          "Click on a question to highlight it": "Je kunt op de onderstaande vragen klikken om deze te markeren. Gemarkeerde vragen vallen op, zo wordt het inzichtelijk welke vragen je graag wilt bespreken.",
          "Print results": "Print de uitkomsten",
          "Download results": "Download de uitkomsten",
        }
      },
      en: {
        translation: {
          "Completely Disagree": "completely disagree",
          "Completely Agree": "completely agree",
          "Score": "Score",
          "Question": "Question",
          "Answer": "Answer",
          "Click on a question to highlight it": "You can click on the questions to highlight them. Highlighted questions stand out, to easily point out questions you'd like to speak about.",
          "Print results": "Print the outcomes",
          "Download results": "Print de outcomes",
        }
      }
    },
    lng: "nl", // if you're using a language detector, do not define the lng option
    fallbackLng: "nl",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
