import React, {useContext, useEffect} from "react";
import {IQuestionnaire, IQuestionnaireVariant, questionnaireMap, QuestionnaireVariant} from "../model/IQuestionnaire";
import {ThemeContext} from "../theme/theme";
import SectionsCard from "../component/Questionnaire/SectionsCard";
import {selectQuestionnaire, setQuestionnaire} from "../features/questionnaire/questionnaireSlice";
import {useAppDispatch, useAppSelector} from "../app/hooks";


interface Props {
  variant: QuestionnaireVariant;
}

const QuestionnairePage: React.FC<Props> = ({variant}) => {
  const questionnaire = useAppSelector(selectQuestionnaire);
  const {setTheme} = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  useEffect(() => {

    const questionnaireVariant = questionnaireMap[variant];

    if(setTheme) {
      setTheme(questionnaireVariant.theme);
    }

    const fetchData = async () => {
      const selectedQuestionnaire = await fetchQuestionnaire(questionnaireVariant);
      dispatch(setQuestionnaire(selectedQuestionnaire));
    }

    fetchData()
        .catch(console.error);

  }, [variant, setTheme, dispatch]);

  async function fetchQuestionnaire(questionnaire: IQuestionnaireVariant) {
    const response = await fetch(questionnaire.questionsLink);
    return await response.json() as IQuestionnaire;
  }

  if(!questionnaire) return <div>Loading...</div>

  return (
        <SectionsCard />
  )
};

export default QuestionnairePage;
