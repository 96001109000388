import React, {useState} from 'react';
import {Container, ThemeProvider} from "@mui/material";
import AppRouter from "./AppRouter";
import Header from "./component/Header";
import defaultTheme, {ThemeContext} from "./theme/theme";
import {BrowserRouter} from "react-router-dom";
import "./i18n/i18n"

function App() {
  const [theme, setTheme] = useState(defaultTheme);

  return (
      <ThemeContext.Provider value={{theme, setTheme}}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Header/>
            <Container id="primaryContent">
              <AppRouter/>
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </ThemeContext.Provider>
  );
}

export default App;
