import defaultTheme, {childTheme, juniorTheme, simpleTheme} from "../theme/theme";
import {Theme} from "@mui/material";

export interface IQuestionnaire {
  variant: QuestionnaireVariant;
  title: string;
  introduction?: IQuestionnaireIntroduction;
  sections: IQuestionnaireSection[]
}

export interface IQuestionnaireIntroduction {
  title: string;
  content: string;
}

export interface IQuestionnaireSection {
  title: string;
  color: string;
  image: string;
  questions: IQuestionnaireQuestion[];
}

export interface IQuestionnaireQuestion {
  id: string;
  text: string;
  type?: "DEFAULT" | "SIMPLE" | "SIMPLE_REVERSED"
}

export interface IQuestionnaireVariant {
  theme: Theme;
  questionsLink: string;
  title: string;
  description: string;
  image: string;
  tag?: string;
  href: string;
}

export type QuestionnaireVariant = 'adults' | 'adults-en' | 'simple' | 'child' | 'junior';

export const questionnaireMap: {[Property in QuestionnaireVariant]: IQuestionnaireVariant} = {

  "adults": {
    theme: defaultTheme,
    questionsLink: "/questions/adults.json",
    title: "VOLWASSENEN",
    description: "De complete versie voor volwassenen",
    image: "/logo.png",
    tag: "/img/iPH_MPG_LOSSE BUTTON_Volwassenen.png",
    href: "/adults"
  },
  "simple": {
    theme: simpleTheme,
    questionsLink: "/questions/simple.json",
    title: "EENVOUDIG",
    description: "Vereenvoudigde variant",
    image: "/logo.png",
    tag: "/img/iPH_MPG_LOSSE BUTTON_Eenvoudig.png",
    href: "/simple"
  },
  "child": {
    theme: childTheme,
    questionsLink: "/questions/child.json",
    title: "KIND 8-16 JAAR",
    description: "Vragenlijst voor kinderen",
    image: "/logo.png",
    tag: "/img/iPH_MPG_LOSSE BUTTON_Kind.png",
    href: "/child"
  },
  "junior": {
    theme: juniorTheme,
    questionsLink: "/questions/junior.json",
    title: "JONGEREN 16-25 JAAR",
    description: "Vragenlijst voor jongeren",
    image: "/logo.png",
    tag: "/img/iPH_MPG_LOSSE BUTTON_Jongeren.png",
    href: "/junior"
  },
  "adults-en": {
    theme: defaultTheme,
    questionsLink: "/questions/adults-en.json",
    title: "English",
    description: "The English questionnaire for adults",
    image: "/logo.png",
    href: "/adults-en"
  },
}
