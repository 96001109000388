import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {useLocation} from "react-router-dom";
import './Header.css'
import {useAppSelector} from "../app/hooks";
import {selectQuestionnaire} from "../features/questionnaire/questionnaireSlice";
import Typography from "@mui/material/Typography";


const Header = () => {
  const questionnaire = useAppSelector(selectQuestionnaire);
  const [atQuestionnaireSelection, setAtQuestionnaireSelection] = useState(window.location.pathname === "/");
  const location = useLocation();

  useEffect(() => {
    setAtQuestionnaireSelection(window.location.pathname === "/")
  }, [location.pathname]);

  return (
      <Box sx={{flexGrow: 1}}>
          <AppBar position="static" sx={{backgroundImage: atQuestionnaireSelection ? "url('/img/banner_bg.svg')" : "inherit"}}>
          <Toolbar>
            <Box component="img" id="home-logo" src="/img/mpg_logo_transparant_white.png" alt="Logo" sx={{position: 'absolute', left: '20px'}} onClick={() => window.location.href = "https://mijnpositievegezondheid.nl/"}/>
              {!atQuestionnaireSelection && questionnaire && (
                    <Typography fontWeight="bold" variant="h6" sx={{position: 'absolute', right: '30px'}}>{questionnaire.title}</Typography>
              )}
          </Toolbar>
        </AppBar>
      </Box>
  );
}
export default Header;
