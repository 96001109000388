import React from "react";
import "./SectionTrail.css"
import SectionTrail from "./SectionTrail";
import {useAppSelector} from "../../app/hooks";
import {selectQuestionnaire} from "../../features/questionnaire/questionnaireSlice";

const SectionTrails = () => {

  const questionnaire = useAppSelector(selectQuestionnaire);

  return (
        <div className="trail">
          {questionnaire?.sections.map((section, index) => {
              return <SectionTrail key={section.title + index} section={section} sectionIndex={index} barColor={section.color} />
          }
          )}
        </div>
  )
}

export default SectionTrails;
