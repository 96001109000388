import { styled } from '@mui/material/styles';
import { Card } from "@mui/material";
import { CardProps } from "@mui/material/Card/Card";


const ThemedCard = styled(Card)<CardProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '40px',
  height: 'calc(100vh - 180px)',
  minHeight: '550px !important',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(90vh - 180px)'
  },
  marginBottom: '10px',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  },
  '.MuiCardHeader-title': { color: 'white' },
  '.MuiCardHeader-subheader': { color: '#bbb' },
}));

export default ThemedCard;
