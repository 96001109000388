import React from "react";
import { CardActions, CardContent, CardHeader } from "@mui/material";
import SectionTrails from "./SectionTrails";
import QuestionsViewer from "./QuestionsViewer";
import ThemedCard from "../card/ThemedCard";
import ReadSpeakerButton from "../button/ReadSpeakerButton";
import { useAppSelector } from "../../app/hooks";
import { selectShowIntroduction } from "../../features/questionnaire/questionnaireSlice";
import QuestionnaireIntroduction from "./QuestionnaireIntroduction";


const SectionsCard = () => {
  const showIntroduction = useAppSelector(selectShowIntroduction);

  if (showIntroduction) {
    return (
      <ThemedCard className="box" raised={true} sx={{ minHeight: '450px' }}>
        <CardContent sx={{ height: '100%' }}>
          <QuestionnaireIntroduction />
        </CardContent>
      </ThemedCard>
    )
  }

  return (
    <>
      <ThemedCard className="box" raised={true} sx={{ minHeight: '450px' }}>
        <CardHeader avatar={<ReadSpeakerButton/>} />
        <CardContent sx={{ height: '100%' }}>
          <QuestionsViewer />
        </CardContent>
        <CardActions>
          <SectionTrails />
        </CardActions>
      </ThemedCard>
    </>
  )
};

export default SectionsCard;
