import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./app/store";
import { initializeApp } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkI0RCk2EM2ovCCjGWzNtwo8o0PbL46AQ",
  authDomain: "mijn-positieve-gezondhei-ef9f7.firebaseapp.com",
  projectId: "mijn-positieve-gezondhei-ef9f7",
  storageBucket: "mijn-positieve-gezondhei-ef9f7.appspot.com",
  messagingSenderId: "877168710441",
  appId: "1:877168710441:web:6ac84428b33598e983dd38"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
      <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
