import "./NavButton.css"
import React from "react";
import {useTheme} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import {selectActiveQuestionAnswered} from "../../features/questionnaire/questionnaireSlice";
import Box from "@mui/material/Box";

interface Props {
  onClick(): any;
  direction: 'next' | 'prev';
  color?: string;
}

const NavButton: React.FC<Props> = ({onClick, direction, color}) => {
  const theme = useTheme();
  const questionAnswered = useAppSelector(selectActiveQuestionAnswered);

  const handleClick = () => {
    if(questionAnswered) onClick();
  }

  const isNext = direction === 'next';

  return (
      <Box component="svg" sx={{top: {xs: "40%", sm: "50%"}}} onClick={handleClick}  xmlns="http://www.w3.org/2000/svg" className={`${direction} ${!questionAnswered && 'disabled'}`} width="56.898" height="91" viewBox="0 0 56.898 91">
        <path
            d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z"
            transform={`translate(${isNext ? "56.898" : "0 91"}) rotate(${isNext ? 90 : -90})`}
            fill={`${color ? color : theme.palette.primary.main}`} />
      </Box>
  )
}

export default NavButton;
