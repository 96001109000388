import React from "react";
import {questionnaireMap} from "../model/IQuestionnaire";
import {Grid} from "@mui/material";
import "./WelcomePage.css";
import VariantCard from "../component/Questionnaire/VariantCard";

const WelcomePage: React.FC = () => {

  return (
      <Grid sx={{mt: 1}} container spacing={5} justifyContent="left" alignItems="stretch">
        {Object.values(questionnaireMap).map((questionnaireVariant) =>
            <VariantCard key={questionnaireVariant.title} questionnaireVariant={questionnaireVariant}/>
        )}
      </Grid>
  )
};

export default WelcomePage;
