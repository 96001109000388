import {useAppSelector} from "../../app/hooks";
import {selectQuestionnaire, setIntroductionShown} from "../../features/questionnaire/questionnaireSlice";
import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import {useDispatch} from "react-redux";

const QuestionnaireIntroduction = () => {
  const questionnaire = useAppSelector(selectQuestionnaire)
  const dispatch = useDispatch();

  const introductionShown = () => {
    dispatch(setIntroductionShown())
  }

  return (
      <Stack alignItems="center" justifyContent="space-evenly" sx={{height: '100%'}}>
        <Typography variant="h4" textAlign="center">
          {questionnaire?.introduction?.title || ""}
        </Typography>
        <Typography variant="body2" textAlign="center" padding={5}>
          {questionnaire?.introduction?.content || ""}
        </Typography>
        <Button onClick={introductionShown} color="primary" variant="contained">Start</Button>
      </Stack>
  )
}

export default QuestionnaireIntroduction;
