import {GivenAnswers} from "./questionnaireSlice";

export type QuestionnaireResults = { [section: number]: number };

export function fetchResults(givenAnswers: GivenAnswers) {

  return new Promise<{ data: QuestionnaireResults }>((resolve) => {
    // fetch('http://127.0.0.1:5001/mijn-positieve-gezondhei-ef9f7/europe-west1/getResults', {
    fetch('https://europe-west1-mijn-positieve-gezondhei-ef9f7.cloudfunctions.net/getResults', {
      method: 'POST',
      body: JSON.stringify(givenAnswers)
    }).then(value => resolve(value.json()))
  });
}
