import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectActiveQuestion, selectGivenAnswer, setGivenAnswer} from "../../features/questionnaire/questionnaireSlice";

const SimpleAnswerOption = () => {

  const givenAnswer = useAppSelector(selectGivenAnswer);
  const activeQuestion = useAppSelector(selectActiveQuestion);
  const dispatch = useAppDispatch();

  const noSmiley = activeQuestion?.type === "SIMPLE_REVERSED" ? "😀" : "😧";
  const yesSmiley = activeQuestion?.type === "SIMPLE_REVERSED" ? "😧" : "😀";

  const handleButtonClick = ((score: number) => {
    dispatch(setGivenAnswer(score));
  })

  return (
      <Stack sx={{width: {xs: '80%', sm: '50%', md: '30%'}}} spacing={5}>
        <Button color="primary" variant={givenAnswer === 1 ? "contained" : "outlined"} onClick={() => handleButtonClick(1)}>
          <Typography padding={2}>{noSmiley} Nee</Typography>
        </Button>
        <Button color="primary" variant={givenAnswer === 2 ? "contained" : "outlined"} onClick={() => handleButtonClick(2)}>
          <Typography padding={2}>😐 Een beetje</Typography>
        </Button>
        <Button color="primary" variant={givenAnswer === 3 ? "contained" : "outlined"} onClick={() => handleButtonClick(3)}>
          <Typography padding={2}>{yesSmiley} Ja</Typography>
        </Button>
      </Stack>
)}

export default SimpleAnswerOption
