import React, {useEffect, useState} from "react";
import {IQuestionnaireSection} from "../../model/IQuestionnaire";
import Box from "@mui/material/Box";
import {createTheme, LinearProgress, Stack, ThemeProvider} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import {
  selectActiveQuestionIndex,
  selectActiveSection,
  selectActiveSectionIndex
} from "../../features/questionnaire/questionnaireSlice";
import Typography from "@mui/material/Typography";

interface Props {
  section: IQuestionnaireSection
  sectionIndex: number;
  barColor: string;
}

const SectionTrail: React.FC<Props> = ({section, sectionIndex, barColor}) => {

  const [percentage, setPercentage] = useState(0);
  const activeSectionIndex = useAppSelector(selectActiveSectionIndex);
  const activeQuestionIndex = useAppSelector(selectActiveQuestionIndex);
  const activeSection = useAppSelector(selectActiveSection);
  const active = sectionIndex === activeSectionIndex;
  const theme = createTheme({
    palette: {
      primary: {
        main: barColor,
        contrastText: '#fff'
      }
    },
    typography: {
      body1: {
        fontSize: "1.0625rem",
        [`@media screen and (max-width: 600px)`]: {fontSize: "0.6785rem"},
        [`@media screen and (max-width: 350px)`]: {fontSize: "0.4785rem"}
      }
    }
  });

  useEffect(() => {
    if (sectionIndex < activeSectionIndex) {
      setPercentage(100);
    } else if (sectionIndex > activeSectionIndex) {
      setPercentage(0);
    } else if (active) {
      setPercentage(((activeQuestionIndex + 1) / (activeSection?.questions.length || 0)) * 100)

    }
  }, [active, activeQuestionIndex, activeSection?.questions.length, activeSectionIndex, sectionIndex]);

  return (
      <Stack className={`${active ? 'active' : ''}`} alignItems="center" justifyContent="center" direction="column">
      <ThemeProvider theme={{...theme}}>
          <LinearProgress variant="determinate" value={percentage} sx={{width: '100%', mb: 2}}/>
        <Box component="img" src={section.image} alt={section.title} sx={{width: '5vw', maxWidth: '50px', mb: 2}}/>
        {active ?
            <Typography
                color={section.color}
                whiteSpace="nowrap"
                sx={{position: "absolute", bottom: 0}}
            >{section.title}</Typography>
            :
            <Typography sx={{position: "absolute", bottom: 0}}>&nbsp;</Typography>
        }
      </ThemeProvider>
      </Stack>
  )
}

export default SectionTrail;
