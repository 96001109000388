import {Button, Card, CardActions, CardContent, CardMedia, Grid, ThemeProvider} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {IQuestionnaireVariant} from "../../model/IQuestionnaire";

interface Props {
  questionnaireVariant: IQuestionnaireVariant;
}

const VariantCard: React.FC<Props> = ({questionnaireVariant}) => {

  return (
      <ThemeProvider theme={questionnaireVariant.theme}>
        <Grid item xs={12} sm={6} md={3} key={questionnaireVariant.title}>
          <Card>
            <CardMedia
                sx={{minHeight: 100, width: '100%', backgroundSize: 'contain'}}
                image={questionnaireVariant.image}
                title={questionnaireVariant.title}
            />
            {/*<Chip size="small" color="primary" label={questionnaireVariant.title} sx={{width: '60%', marginLeft: '30%', borderRadius: '8px'}} />*/}
            {questionnaireVariant.tag &&
                <CardMedia
                    sx={{minHeight: 40, width: '100%', backgroundSize: 'contain'}}
                    image={questionnaireVariant.tag}
                />
            }
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {questionnaireVariant.description}
              </Typography>
            </CardContent>
            <CardActions sx={{position: 'relative', bottom: '5px'}}>

              <Button fullWidth variant="contained" size="small" href={questionnaireVariant.href}>Kies</Button>
            </CardActions>
          </Card>
        </Grid>
      </ThemeProvider>
  )
}

export default VariantCard;
